import { useEffect, useId, useRef, useState } from "react";

import { json } from "@remix-run/node";
import type { LoaderFunction, MetaFunction } from "@remix-run/node";
import { useLoaderData } from "@remix-run/react";
import debounce from "lodash.debounce";
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { ClientOnly } from "remix-utils";

import { LoaderComman } from "~/components/loader/loader";
import { AutoLocation } from "~/components/organisms/AutoLocation/AutoLocation.client";
import { DummyComponentFallBack, getCategories } from "~/components/search";
import {
  setFilteredJobsToStore,
  setJobsToStore,
  setPageNumberToStore,
  setPrevScrollPosToStore,
  setTotalRecordsToStore,
} from "~/redux/reducers/jobs/job.slice";
import type { Job } from "~/types";
import NewCard from "./newCard";
import NewJobCard from "./newJobCard";
import Select from "react-select";
import {
  IndustryData,
  RadiusData,
  TypeData,
} from "~/constants/dropdownConstants";
import { Pagination, Stack } from "@mui/material";
import { JobCardSkeleton } from "~/components/loader/skeletonLoader";
import { useWindowWidth } from "~/components/windowWidth";

export type LoaderData = {
  queryTitle: string;
  queryLocation: string;
  queryRadius: number;
  queryIndustry: string;
  queryType: string;
  jobs: Job[];
  GOOGLE_API_KEY: any;
  baseUrl?: string;
  lat: string;
  lng: string;
  search?: any;
};

export const loader: LoaderFunction = async ({ request }) => {
  const url = new URL(request.url);
  const search = new URLSearchParams(url.search);
  const queryTitle = search.get("jobTitle") || "";
  const queryLocation = search.get("location") || "";
  const queryRadius = Number(search.get("radius")) || 15;
  const queryIndustry = search.get("industry") || "";
  const queryType = search.get("jobType") || "";
  const lat = search.get("lat") || "";
  const lng = search.get("lng") || "";
  const baseUrl = process.env.API_BASE_URL;
  const GOOGLE_API_KEY = process.env.GOOGLE_API_KEY;

  return json<LoaderData>({
    queryTitle: queryTitle || "",
    queryRadius: queryRadius || 15,
    queryLocation: queryLocation || "",
    queryIndustry: queryIndustry || "",
    queryType: queryType || "",
    lat,
    lng,
    jobs: [],
    GOOGLE_API_KEY,
    baseUrl,
  });
};

export const meta: MetaFunction = () => ({
  "robots": "noindex, follow",
});

export const getJobs = async (
  pageNumber = 1,
  pageSize = 1000,
  jobTitle = "",
  radius = 15,
  industry = "",
  type = "",
  latitude = "",
  longitude = ""
) => {
  const realJobsAPI =
    "https://webapi.theqbis.com/api/ClientLiveJob/GetClientLiveJobs";
  const environment =
    process.env.NODE_ENV === "development" ? realJobsAPI : realJobsAPI;

  const body: any = {
    pageNumber,
    pageSize,
    radius: Number(radius),
  };

  if (latitude) {
    body.latitude = latitude.toString();
  }
  if (longitude) {
    body.longitude = longitude.toString();
  }
  if (industry) {
    body.industry = industry;
  }
  if (type) {
    body.type = type;
  }
  if (jobTitle) {
    body.jobTitle = jobTitle;
  }

  return fetch(environment, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }).then((res) => res.json());
};

export default function JobsIndex() {
  const reduxData = useSelector((state: any) => state.job);

  const {
    queryTitle,
    queryLocation,
    queryRadius,
    queryIndustry,
    queryType,
    GOOGLE_API_KEY,
    baseUrl,
    lat,
    lng,
  } = useLoaderData<LoaderData>();
  const [activeBlockId, setActiveBlockId] = useState(0);
  const [activeJobData, setActiveJobData]: any = useState({});
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 768;

  const [filteredJobs, setFilteredJobs] = useState<Job[]>([]);
  const [jobs, setJobs] = useState<Job[]>([]);
  const [title, setTitle] = useState(queryTitle);
  const [location, setLocation] = useState<any>(
    queryLocation ? { value: { description: queryLocation } } : null
  );

  const [latLng, setLatLng] = useState<null | { lat: number; lng: number }>(
    null
  );

  const [latitude, setLatitude] = useState<any>(lat ? lat : "");
  const [longitude, setLongitude] = useState<any>(lng ? lng : "");
  const [radius, setRadius] = useState(queryRadius);
  const [industry, setIndustry] = useState(queryIndustry);
  const [jobType, setJobType] = useState(queryType || "");
  const [loading, setLoading] = useState(jobs.length ? false : true);
  const [prevScrollPos, setPrevScrollPos] = useState(reduxData.prevScrollPos);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [pageNumber, setPageNumber] = useState(1);
  const [isChanged, setChanged] = useState(false);
  const [categories, setCategories] = useState(reduxData.categoryList);
  const [totalRecords, setTotalRecords] = useState(reduxData.totalRecords);
  const dispatch = useDispatch();

  const scrollDivRef = useRef<any>(null);

  const fetchData = async (isStoreData = true) => {
    try {
      setLoading(true);
      const { data, totalRecord } = await getJobs(
        pageNumber,
        pageSize,
        title,
        radius,
        industry,
        jobType,
        latitude,
        longitude
      );

      if (data?.length) {
        const jobsData = isStoreData ? [...jobs, ...data] : data;
        setActiveBlockId(jobsData[0]?.id);
        setActiveJobData(jobsData[0]);

        setJobs(jobsData);
        setTotalRecords(totalRecord);

        if (isStoreData) {
          dispatch(setJobsToStore(jobsData));
          dispatch(setTotalRecordsToStore(totalRecord));
        }

        if (!categories?.length) {
          const { data } = await getCategories();
          setCategories(data);
        }

        setFilteredJobs(jobsData);
        dispatch(setFilteredJobsToStore(jobsData));

        if (scrollDivRef.current) {
          scrollDivRef.current.scrollTo(0, prevScrollPos);
        }

        setLoading(false);
        return;
      }

      setJobs([]);
      setTotalRecords(0);
      dispatch(setJobsToStore([]));
      dispatch(setTotalRecordsToStore(0));

      setFilteredJobs([]);
      dispatch(setFilteredJobsToStore([]));

      if (scrollDivRef.current) {
        scrollDivRef.current.scrollTo(0, prevScrollPos);
      }
      setLoading(false);
      setChanged(false);
    } catch (err: any) {
      setLoading(false);
      setChanged(false);
    }
  };

  useEffect(() => {
    const handleIframeRemoval = () => {
      const iframes = document.getElementsByTagName("iframe");
      for (let i = 0; i < iframes.length; i++) {
        const iframe = iframes[i];
        iframe.remove();
      }
    };

    handleIframeRemoval();

    const interval = setInterval(() => {
      handleIframeRemoval();
    }, 1000);

    const updateHeight = () => {
      document.documentElement.style.setProperty(
        "--window-inner-height",
        `${window.innerHeight - 321}px`
      );
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);
    if (!filteredJobs.length) {
      fetchData();
    }

    return () => {
      window.removeEventListener("resize", updateHeight);
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const handleScroll = debounce((e: any) => {
      const scrollContainer = e.target;
      const currentScrollPos = e.target.scrollTop;
      const maxScrollPos =
        scrollContainer.scrollHeight - scrollContainer.clientHeight;

      if (
        currentScrollPos > reduxData.prevScrollPos &&
        currentScrollPos >= maxScrollPos - 1
      ) {
        setChanged(true);
        setPrevScrollPos(currentScrollPos);
      }
      dispatch(setPrevScrollPosToStore(currentScrollPos));
    }, 100);

    const scrollContainer = document.querySelector(".card-height");
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [prevScrollPos]);

  useEffect(() => {
    if (prevScrollPos && isChanged) {
      setPageNumber(pageNumber + 1);
      dispatch(setPageNumberToStore(pageNumber + 1));
    }
  }, [prevScrollPos]);

  useEffect(() => {
    if (isChanged) {
      fetchData(false);
    }
  }, [title, location, radius, industry, jobType]);

  useEffect(() => {
    if (isChanged && totalRecords !== jobs.length) {
      fetchData();
    }
  }, [pageNumber]);

  const updateFilter = (filterType: string, value: any) => {
    setChanged(true);
    setPageNumber(1);
    setCurrentPage(1);
    setPrevScrollPos(0);

    switch (filterType) {
      case "title":
        setTitle(value);
        break;
      case "radius":
        setRadius(Number(value) || 15);
        break;
      case "industry":
        setIndustry(value);
        break;
      case "jobType":
        setJobType(value);
        break;
    }
  };

  function transformCategories(categories: any[]) {
    return categories?.map((category) => ({
      value: category.categoryName,
      label: category.categoryName,
    }));
  }
  const transformedCategory = transformCategories(categories);

  const handlePageChange = (event: any, newPage: number) => {
    setCurrentPage(newPage);
    setPageNumber(newPage);
    setChanged(true);
    setPrevScrollPos(0);
  };

  useEffect(() => {
    async function load() {
      if (location) {
        const [res] = await geocodeByAddress(location.value.description);
        const { lat, lng } = await getLatLng(res);
        setLatitude(String(lat));
        setLongitude(String(lng));
        setLatLng({ lat, lng });
      }
      return null;
    }
    load();
  }, [location]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    if (title) {
      searchParams.set("jobTitle", title);
    } else {
      searchParams.delete("jobTitle");
    }

    if (latitude) {
      searchParams.set("lat", String(latitude));
    } else {
      searchParams.delete("lat");
    }

    if (longitude) {
      searchParams.set("lng", String(longitude));
    } else {
      searchParams.delete("lng");
    }

    if (radius) {
      searchParams.set("radius", String(radius));
    } else {
      searchParams.delete("radius");
    }

    if (industry) {
      searchParams.set("industry", industry);
    } else {
      searchParams.delete("industry");
    }

    if (jobType) {
      searchParams.set("jobType", jobType);
    } else {
      searchParams.delete("jobType");
    }

    if (latLng) {
      setLatitude(latLng.lat);
      setLongitude(latLng.lng);
      fetchData(false);
    }

    url.search = searchParams.toString();
    window.history.replaceState({}, "", url.toString());
    setPageNumber(1);
    setCurrentPage(1);
  }, [title, location, radius, industry, jobType, latLng]);

  useEffect(() => {
    if (queryLocation) {
      setLocation({ value: { description: queryLocation } });
    }
  }, [queryLocation]);

  return (
    <LoaderComman isLoading={loading}>
      <div className="mx-auto max-w-7xl">
        <div className="grid gap-4 px-8 py-6 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
          <Select
            options={transformedCategory}
            name="title"
            placeholder="Job Title"
            id="long-value-select"
            instanceId={useId()}
            defaultValue={
              queryTitle ? { label: queryTitle, value: queryTitle } : null
            }
            className="basic-single w-full shadow-md rounded-md z-[11]"
            classNamePrefix="select"
            isDisabled={false}
            isLoading={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            onChange={(e) => updateFilter("title", e?.value)}
          />
          <ClientOnly fallback={<DummyComponentFallBack />}>
            {() => (
              <AutoLocation apiKey={GOOGLE_API_KEY} setLocation={setLocation} />
            )}
          </ClientOnly>

          <Select
            options={RadiusData}
            className="basic-single w-full shadow-md rounded-md"
            classNamePrefix="select"
            id="long-value-select"
            instanceId={useId()}
            defaultValue={radius ? { label: radius, value: radius } : null}
            isDisabled={false}
            isLoading={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            name="radius"
            placeholder="Radius"
            onChange={(e) => updateFilter("radius", e?.value)}
          />
          <Select
            className="basic-single w-full shadow-md rounded-md"
            classNamePrefix="select"
            id="long-value-select"
            instanceId={useId()}
            isDisabled={false}
            isLoading={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            name="industry"
            options={IndustryData}
            placeholder="Industry"
            defaultValue={
              queryIndustry
                ? { label: queryIndustry, value: queryIndustry }
                : null
            }
            onChange={(e) => updateFilter("industry", e?.value)}
          />
          <Select
            className="basic-single w-full shadow-md rounded-md"
            classNamePrefix="select"
            id="long-value-select"
            instanceId={useId()}  
            isDisabled={false}
            isLoading={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            name="jobType"
            options={TypeData}
            placeholder="Type"
            onChange={(e) => updateFilter("jobType", e?.value)}
            defaultValue={
              queryType ? { label: queryType, value: queryType } : null
            }
          />
        </div>

        <div className="px-6 py-8 md:grid md:grid-cols-3 gap-4">
          <div className="col-span-1">
            <div className="no-scrollbar overflow-y-auto max-h-[calc(100vh-100px)]">
              {filteredJobs
                .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                ?.map((job: any, index) => (
                  <div key={index}>
                    {loading ? (
                      <JobCardSkeleton />
                    ) : (
                      <>
                        <NewCard
                          activeBlockId={activeBlockId}
                          setActiveBlockId={setActiveBlockId}
                          setActiveJobData={setActiveJobData}
                          jobDetails={job}
                        />
                        {!isMobile && activeBlockId === job.id && (
                          <div className="block md:hidden mt-4">
                            <NewJobCard
                              jobid={activeJobData.id}
                              jobUrl={activeJobData.jobUrl}
                              {...activeJobData}
                              shareUrl={baseUrl}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ))}
            </div>
            {filteredJobs.length > 0 && (
              <Stack className="flex justify-center items-center mt-4 rounded-2xl m-3 p-4 bg-white">
                <Pagination
                  count={Math.ceil(totalRecords / pageSize)}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </Stack>
            )}
          </div>

          <div className="col-span-2 w-full hidden md:block">
            <div className="w-full bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden mt-0">
              <div className="">
                <div
                  className={`flex justify-center dark:text-white ${
                    loading || !filteredJobs.length ? "p-4" : "d-none"
                  }`}
                >
                  {loading
                    ? "Loading..."
                    : !filteredJobs.length && "There are no matching jobs."}
                </div>
                {filteredJobs.length > 0 &&
                  activeBlockId === activeJobData.id && (
                    <NewJobCard
                      jobid={activeJobData.id}
                      jobUrl={activeJobData.jobUrl}
                      {...activeJobData}
                      shareUrl={baseUrl}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoaderComman>
  );
}
